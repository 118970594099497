import React from 'react';
import { costCoursePrice } from '../../Shared/config/constants';
import styles from './CourseCostBlock.module.scss';

export const CourseCostBlock = ({ course }) => {
    const installmentPlan = costCoursePrice[course].totalCost / 5;

    const conversionToDollars = Math.ceil(installmentPlan / costCoursePrice.dollarValue);

    return (
        <div className={styles.costContainer}>
            <h2 className={styles.courseCost}>Стоимость курса</h2>
            <h3 className={styles.cost}>{costCoursePrice[course].totalCost} BYN</h3>
            <ul>
                <strong>Оплата по системе внутренней рассрочки:</strong>
                <li>
                    {' '}
                    - {installmentPlan} BYN / {conversionToDollars} USD до начала занятий
                </li>
                <li>
                    {' '}
                    - {installmentPlan} BYN / {conversionToDollars} USD через 4 недели после начала обучения
                </li>
                <li>
                    {' '}
                    - {installmentPlan} BYN / {conversionToDollars} USD через 7 недель после начала обучения
                </li>
                <li>
                    {' '}
                    - {installmentPlan} BYN / {conversionToDollars} USD через 11 недель после начала обучения
                </li>
                <li>
                    {' '}
                    - {installmentPlan} BYN / {conversionToDollars} USD до конца обучения
                </li>
            </ul>
            <p className={styles.installmentProgram}>
                Также в школе действуют <strong>программы рассрочки и кредитования</strong> БЕЗ процентов и переплат* с
                банками-партнерами на срок от 2 до 24 месяцев. Подробнее о системах рассрочек узнавайте по телефону у
                менеджеров.
            </p>
            <h3 className={styles.discount}>Наши спецпредложения:</h3>
            <div>
                При единовременной оплате действует скидка 10% Для студентов. Приведи друга – учитесь со скидкой 5%
                каждый. Скидки студентам и школьникам 8%. При оплате в 2 этапа скидка 5%
                <p className={styles.inDetail}>
                    *Более подробную информацию по программам рассрочки и кредитования можно узнать у менеджеров.
                </p>
            </div>
        </div>
    );
};
