import React from 'react';
import { LogoIcon } from '../index';
import styles from './Loader.module.scss';

export const Loader = () => {
    return (
        <div className={styles.loader}>
            <div className={styles.dp_logo_container}>
                <div className={styles.dp_logo}>
                    <LogoIcon />
                </div>
            </div>
        </div>
    );
};
