export const BASE_URL = 'https://dev1-97bkye4qw-kirill1881.vercel.app/add/';

export const sendRequest = async ({ path, method, body, headers }) => {
    const requestUrl = `${BASE_URL}/${path}`;
    const options = {
        method,
        headers: {
            ...headers,
        },
        body: body,
    };

    return await fetch(requestUrl, options);
};
