import { lazy } from 'react';

export { ReactComponent as LogoIcon } from './img/logo.svg';
export { ReactComponent as FacebookIcon } from './img/faceboock.svg';
export { ReactComponent as InstagramIcon } from './img/instagram.svg';
export { ReactComponent as TelegramIcon } from './img/telegram.svg';
export { ReactComponent as TicTocIcon } from './img/tik-tok.svg';
export { ReactComponent as ArrowIcon } from './img/arrow.svg';
export { ReactComponent as CakeIcon } from './img/cake.svg';
export { ReactComponent as CupIcon } from './img/cup.svg';
export { ReactComponent as BagIcon } from './img/handbag.svg';
export { ReactComponent as FragmentIcon } from './img/fragment.svg';
export { ReactComponent as OneStarIcon } from './img/oneStar.svg';
export { ReactComponent as PlusIcon } from './img/plus.svg';
export { ReactComponent as LineIcon } from './img/line.svg';
export { ReactComponent as ComfortIcon } from './img/comfort.svg';
export { ReactComponent as DevelopmentIcon } from './img/development.svg';
export { ReactComponent as FreeIcon } from './img/freeIcon.svg';
export { ReactComponent as ProspectsIcon } from './img/prospects.svg';
export { ReactComponent as BurstStarIcon } from './img/burst-star.svg';
export { ReactComponent as ArrowButtonIcon } from './img/arrow-button.svg';
export { ReactComponent as ArrowsIcon } from './img/arrows.svg';
export { ReactComponent as TwoStarsIcon } from './img/twoStars.svg';
export { ReactComponent as GroupStars } from './img/groupStars.svg';
export { ReactComponent as BurgerIcon } from './img/burger-menu.svg';
export { ReactComponent as ArrowTabsIcon } from './img/arrowTabs.svg';

export const LazyImage = lazy(() => import('./LazyImage/LazyImage'));

export { Loader } from './Loader/Loader';
export { Accordion } from './Accordion/Accordion';
export { accordionTitle } from './config/constants';
export { LinkComponent } from './LinkComponent/LinkComponent';
export { Button } from './Button/Button';
export { navBtnList } from './config/constants';
export { coursesList } from './config/constants';
export { contacts } from './config/constants';
export { titleCoursesList } from './config/constants';
export { teachersList } from './config/constants';
export { coursePageText } from './config/constants';
export { TeacherCard } from './TeacherCard/TeacherCard';
export { Input } from './Input/Input';
export { InputPhone } from './InputPhone/InputPhone';
export { CheckBox } from './CheckBox/CheckBox';
export { onlineAndOfflinePagesContent } from './config/constants';
export { tabsList } from './config/constants';
export { ToggleSwitch } from './ToggleSwitch/ToggleSwitch';
