import React, { useEffect, useState } from 'react';
import { ArrowIcon, coursesList, LogoIcon, navBtnList, TwoStarsIcon } from '../../Shared';
import { Social } from '../Header/Social';
import { Link, useLocation } from 'react-router-dom';
import { path } from '../RouterComponent/config/config';
import stylesBurger from './BurgerMenu.module.scss';

export const BurgerMenu = () => {
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setOpen(false);
    }, [pathname]);

    return (
        <div className={stylesBurger.burger_container}>
            <div className={stylesBurger.burger_container_btn}>
                {open ? (
                    <div className={stylesBurger.burger_container_btn_close} onClick={handleOpen}>
                        <span></span>
                        <span></span>
                    </div>
                ) : (
                    <div className={stylesBurger.burger_container_btn_open} onClick={handleOpen}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                )}
            </div>
            {open && (
                <div className={stylesBurger.burger_container_menu}>
                    <Link to="/" style={{ alignSelf: 'center' }}>
                        <LogoIcon alt="logo" className={stylesBurger.burger_container_menu_logoIcon} />
                    </Link>
                    <a
                        className={stylesBurger.burger_container_menu_agreement}
                        href="https://drive.google.com/file/d/1zACYs_9ivmK9Q4EuHXa_3MfkdE2aeCdh/view?usp=sharing"
                    >
                        Договор публичной оферты
                    </a>
                    <Link to={path.courses} color="primary" className={stylesBurger.burger_container_menu_courses}>
                        {navBtnList[0].btnName} <ArrowIcon />
                    </Link>
                    <ul className={stylesBurger.burger_container_menu_coursesList}>
                        {coursesList.map(({ courseName, titleKey }, index) => (
                            <li className={stylesBurger.burger_container_menu_coursesList_item} key={index}>
                                <Link
                                    className={stylesBurger.burger_container_menu_coursesList_item_link}
                                    to={path[titleKey]}
                                >
                                    {courseName}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Link to={path.teachers} className={stylesBurger.burger_container_menu_teacher}>
                        {navBtnList[1].btnName}
                    </Link>
                    <div className={stylesBurger.burger_container_menu_contacts}>
                        <h4 className={stylesBurger.burger_container_menu_contacts_title}>Контакты</h4>
                        <p className={stylesBurger.burger_container_menu_contacts_text}>
                            УНП 193676503 Адрес: 220035, Республика Беларусь, г. Минск, ул. Бядули, д. 13, оф. 203 Режим работы:
                            понедельник - пятница с 9:00 до 23:00
                        </p>
                        <p className={stylesBurger.burger_container_menu_contacts_text}>
                            +375(29) 28-49-123 | devplaceacademy@gmail.com
                        </p>
                    </div>
                    <Social className={stylesBurger.burger_container_menu_social} />
                    <TwoStarsIcon className={stylesBurger.burger_container_menu__iconOne} />
                    <TwoStarsIcon className={stylesBurger.burger_container_menu__iconTwo} />
                    <p className={stylesBurger.burger_container_menu_rights}>© 2023 Все права защищены.</p>
                </div>
            )}
        </div>
    );
};
